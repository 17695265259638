h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  font-weight: 700;
}

// -- Top Bar
.top-bar {
  //border-bottom: 10px solid #720E76;
  padding: 0;
  margin-bottom: 20px;
  .top-bar-left {
    background: #444C53;
    padding: 10px;
  }
  .top-bar-right {
    background: rgba(161, 108, 164, 0.5);
  }
  .logo {
    float: left;
    margin-right: 20px;
    img {
      padding-top: 5px;
      width: 60px;
    }
  }
  .title-section {
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    float: left;
    .title {
      font-weight: 900;
      font-size: 16px;
      margin: 0;
    }
    .subtitle {
      font-size: 11px;
      span {
        display: block;
      }
    }
  }
  .menu {
    background-color: rgba(0, 0, 0, 1);
    li {
      border-bottom: 3px solid rgba(161, 108, 164, 0);
      &.active {
        background-color: rgba(161, 108, 164, 1);
        border-bottom: 3px solid rgba(161, 108, 164, 1);
      }
      &:hover {
        border-bottom: 3px solid rgba(161, 108, 164, 1);
      }
    }
    a {
      color: white;
      font-weight: 700;
      font-size: 1em;
    }
  }
}


@media only screen and (min-width: 640px) {
  .top-bar {
    align-items: flex-start;
    background: url("../images/noah-silliman-144948-v2.jpg");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    .top-bar-left {
      background: transparent;
      padding: 40px;
    }
    .logo {
      float: left;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: auto;
        padding: 0;
      }
    }
    .title-section {
      padding: 20px;
      margin: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-left: 5px solid rgba(161, 108, 164, 1);
      .title {
        font-size: 2em;
        line-height: 1em;
        margin: 0 0 10px 0;
        color: white;
      }
      .subtitle {
        font-size: .75em;
      }
    }
    .top-bar-right {
      max-width: 500px;
    }
    .menu {
      background: transparent;
    }
  }
}

// -- Aside
aside {
  > div {
    padding-bottom: 80px;
  }
  section header {
    font-weight: 700;
    font-size: 1.25em;
  }
}

// -- Footer
.footer {
  background-color: #444C53;
}
.footer-content {
  @include grid-row();
  font-size: 0.75rem;
  color: white;
  padding: 20px;
  a {
    color: white;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
  .footer-left {
    margin-bottom: 20px;
    @include breakpoint(small) {
      @include grid-column(12);
    }
    @include breakpoint(large) {
      @include grid-column(3);
    }
  }
  .footer-center {
    margin-bottom: 20px;
    @include breakpoint(small) {
      @include grid-column(12);
    }
    @include breakpoint(large) {
      @include grid-column(4);
      @include grid-column-position(1);
    }
  }
  .footer-right {
    margin-bottom: 20px;
    @include breakpoint(small) {
      @include grid-column(12);
    }
    @include breakpoint(large) {
      @include grid-column(3);
    }
  }
}

// -- needed for sticky footer
body {
  height: auto;
}